import "../activities/Entry";

import { PageProps, graphql } from "gatsby";
import * as React from "react";

const EntryTemplate: React.FC<PageProps> = () => {
  return null;
};

export const query = graphql`
  query EntryTemplateQuery {
    prismicJpNoviceGuideMenu {
      data {
        menus {
          check_text {
            text
          }
          image {
            alt
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          title {
            text
          }
          slug {
            text
          }
        }
        novice_title {
          text
        }
      }
    }

    prismicJpNoviceGuideBanner {
      data {
        title {
          text
        }
        description {
          text
        }
        link_text {
          text
        }
        href {
          text
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default EntryTemplate;
